import * as React from 'react';
import Layout from "../Components/Layout"
import { FooterComponent } from '../Components/FooterComponent';
import HomeIndex from '../modules/Home/HomeIndex';
import HeaderComponent from '../Components/HeaderComponent';
import Seo from '../Components/Seo';

const indexPage = () => {
  return (
    <>
      {/* <Helmet title="Home" defer={false} /> */}
      <Seo/>
      <Layout className="homepage"  >
        <HeaderComponent hasProduct/>
        <HomeIndex  />
        <FooterComponent />
      </Layout>
    </>
  );
};

export default indexPage;
