import * as React from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import styled from 'styled-components';
import { CustomLinkBtn } from '../../../Components/CustomButton';

const CardWrapper = styled.div `
  position: relative;

  width: 100%;
  height: 150px;
  box-shadow: 0px 1px 3px #00000029;

  img {
    width: 100%;
  height: 100%;
    background-position: center;
background-size: cover;
object-fit: cover;
z-index: 3;
transition: transform .4s ease;
  }

  .img-card {
    ${'' /* display: none;
    width: 100%;
  height: 150px; */}
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  }


  &:hover {
    .overlay {
      opacity: 0;
    }

    svg {
        transform: translate(10px);
    }

    img {
      transform: scale(1.04)
    }
  }


`

const CustomCard = styled.div `
display: flex;
width: 100%;
height: 150px;

h5 {
    position: absolute;
    top: 30px;
    left: 30px;
    font-size:1rem;
color: #FFFFFF;
z-index: 2;
}

.link-btn {
    margin: auto;
    z-index: 2;
    position: absolute;
    bottom: 10%;
    left: 30px;
    
    a {
       
        font-size:.73rem;
    color: #FFFFFF;

    svg {
        transition: transform .4s ease;
    }

  
}
}

.overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    background: #2A2828 0% 0% no-repeat padding-box;
      height: 100%;
      width: 100%;
      opacity: 0.5;
      z-index: 1;
      transition: .4s ease;
  }

`

function NewsCard({src, name}) {
    return ( 
    <CardWrapper >
      <div className='img-card'>
      <img src={src} alt='NewsImg' />
      </div>
        <CustomCard >
        <div className='overlay' > </div> 
        <h5>{name}</h5> 
        <div className='link-btn'>
        <CustomLinkBtn text="Learn More"
        icon={ < AiOutlineArrowRight /> }
        url="#" />
        </div> 
        </CustomCard>
    </CardWrapper>
    )
}

export default NewsCard