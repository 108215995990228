import { Link } from "gatsby";
import * as React from "react";
import { BiChevronRight } from "react-icons/bi";
import TextTransition, { presets } from "react-text-transition";
import { CustomLinkBtn } from "../../Components/CustomButton";
import { BlogCard } from "../../Components/CustomCard";
import CustomModal from "../../Components/CustomModal";
import { PatternIcon, WhitePatternIcon } from "../../Components/SVGs";
import {
  CONTACT_PAGE,
  PRODUCTS_PAGE,
  RECEPTION_SUITE_PAGE,
  TRIP_SUITE_HOMEPAGE,
} from "../../constant/routes";
import GetStartedForm from "./components/GetStartedForm";
import NewsCard from "./components/NewsCard";
import ProductCard from "./components/ProductCard";
import VideoCard from "./components/VideoCard";

const HomeIndex = () => {
  const [modalShow, setModalShow] = React.useState(false);

  const TEXTS = ["From anywhere", "Go anywhere"];

  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      5000 // every 5 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <>
      <section className="homepage__content">
        <div className="homepage__content-banner">
          <div className="dot dot2">
            <WhitePatternIcon />
          </div>

          <div className="dot dot3">
            <WhitePatternIcon />
          </div>
          <div className="homepage__content-banner-text container">
            <div className="pattern">
              <WhitePatternIcon />
            </div>
            <h5>Work together,</h5>
            <div className="content__container">
              <ul className="homepage__content__list">
                <li className="homepage__content__container__item">
                  <TextTransition
                    text={TEXTS[index % TEXTS.length]}
                    springConfig={presets.wobbly}
                  />
                </li>
              </ul>
            </div>
            <p>
              Modernize your teams operation with unique and powerful suite of
              software to run your entire business flexibly & smartly without
              limitation.
            </p>

            <div className="banner-btn">
              <Link
                to="https://id.webbermill.com/account/sign-up"
                target="_blank"
              >
                SIGN UP FOR FREE <BiChevronRight />
              </Link>
              <CustomLinkBtn text="REQUEST A DEMO" url={CONTACT_PAGE} />
            </div>
          </div>
        </div>
      </section>
      <section className="homepage__products">
        <div className="homepage__products-content container">
          <div className="header-text ">
            <div className="d-flex justify-content-center align-item-center py-5 title">
              <h1>
                Apps inspired by <strong>your needs</strong>
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 ">
              <ProductCard
                src="https://res.cloudinary.com/webbermill/image/upload/v1679659200/webbermill-website-assets/WSPIcons/Quotation_reyokk.svg"
                product="Quotation"
                url="#"
                description="Increase your conversion rate by creating & sending professional quotations with sign and online payment of your favourite payment processor."
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <ProductCard
                src="https://res.cloudinary.com/webbermill/image/upload/v1679659203/webbermill-website-assets/WSPIcons/Trip_ljixgz.svg"
                soon
                product="Consignment"
                url={TRIP_SUITE_HOMEPAGE}
                description="Stay ahead with smart transportation business with 100% control of planning, collaboration and hassle-free execution tools."
              />
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <ProductCard
                src="https://res.cloudinary.com/webbermill/image/upload/v1679659203/webbermill-website-assets/WSPIcons/Invoice_uxfzlh.svg"
                soon
                product="Invoice"
                url="#"
                description="Create & send professional standard, retainer and recurring invoices automatically based on sales orders that gets you paid faster."
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mb-5">
              <ProductCard
                src="https://res.cloudinary.com/webbermill/image/upload/v1679659201/webbermill-website-assets/WSPIcons/Fleet_lgsdnr.svg"
                product="Fleet"
                soon
                description="Manage vehicles, assign operators, service requests, equipment, fuel history, conduct routine inspections, expenses and insurance easily."
              />
            </div>
          </div>
          <div className="header-text product-btn ">
            <Link to={PRODUCTS_PAGE}>
              <span>View all products</span>
            </Link>
          </div>
        </div>
      </section>
      <div
        className="container hr"
        style={{ paddingInline: 200, marginTop: 50 }}
      >
        <hr />
      </div>
      <section className="homepage__business">
        <div className="homepage__business-content container">
          <div>
            <h5 id="first">
              One platform to manage <strong>your business</strong>
            </h5>
          </div>
          <div className="video-card">
            <VideoCard />
          </div>
        </div>
      </section>
      <div className="container hr" style={{ paddingInline: 200 }}>
        <hr />
      </div>
      <section className="homepage__trust">
        <div className="homepage__trust-content container">
          <div className="text-content">
            <div>
              <h5 id="first">Trusted by </h5>
              <h5 id="second">leading businesses</h5>
            </div>
            <div>
              <p className="breaks">
                Experience reliable, flexiblbe and secure business platform{" "}
                <br />
                for your ever growing business. <br />
                Drive productivity with 21+ integrated applications already{" "}
                <br />
                used by vibrant businesses in all the top industries.
              </p>
              <p className="no-breaks">
                Experience reliable, flexiblbe and secure business platform for
                your ever growing business. Drive productivity with 21+
                integrated applications already used by vibrant businesses in
                all the top industries.
              </p>
            </div>
            <div className="pattern">
              <PatternIcon />
            </div>
          </div>
          <div className="scroll-content">
            <div className="pattern">
              <PatternIcon />
            </div>
            <div className="card-content">
              <NewsCard
                name="Antartic Ltd"
                src="https://res.cloudinary.com/webbermill/image/upload/v1679606045/webbermill-website-assets/static/news/antartic_p8sqk4.jpg"
              />
              <NewsCard
                name="J. K. Horgle Transport & Co. Ltd"
                src="https://res.cloudinary.com/webbermill/image/upload/v1679606043/webbermill-website-assets/static/news/jhk_wfzwcf.jpg"
              />
              <NewsCard
                name="Tinatett Herbal"
                src="https://res.cloudinary.com/webbermill/image/upload/v1679606045/webbermill-website-assets/static/news/herbal_c21gu1.jpg"
              />
              <NewsCard
                name="Kranson Uniforms"
                src="https://res.cloudinary.com/webbermill/image/upload/v1679606045/webbermill-website-assets/static/news/car_pic_nukbrt.jpg"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="homepage__demo">
        <div className="homepage__demo-content container">
          <h5>Get started for free today</h5>
          <p>No expiry date | No payment required</p>
          <div className="banner-btn mt-5 text-white">
            {/* <CustomLinkBtn text="REQUEST A DEMO" url={REQUEST_PAGE} icon={<BiChevronRight />} />    */}
            <Link
              to="https://id.webbermill.com/account/sign-up"
              target="_blank"
            >
              SIGN UP FOR FREE <BiChevronRight />
            </Link>
          </div>
        </div>
      </section>

      <section className="homepage__news">
        <div className="homepage__news-content container">
          <div className="text-content">
            <h5 id="first">
              Stay <strong>up-to-date</strong>
            </h5>
            <div className="blogBtn">
              <Link to="https://blog.webbermill.com/" target="_blank">
                VISIT OUR BLOG
              </Link>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <BlogCard
                img="https://res.cloudinary.com/webbermill/image/upload/v1679606044/webbermill-website-assets/static/news/news1_xkphmu.png"
                tag="Softwares"
                excerpt="Despite technological progress, most construction companies in Africa still rely on manual data entry, spreadsheets, and paperwork for their business processes..."
                topic="Useful Software for Construction Companies"
                link="https://blog.webbermill.com/useful-software-for-contruction-companies/"
              />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
              <BlogCard
                img="https://res.cloudinary.com/webbermill/image/upload/v1679606044/webbermill-website-assets/static/news/news2_shfccx.png"
                tag="Data Loss"
                excerpt="Many organizations rely on technology to perform business operations and store important information. Data protection, therefore, is a critical element of business success..."
                topic="Ways to Prevent Data Loss in your Company"
                link="https://blog.webbermill.com/how-to-prevent-data-loss/"
              />
            </div>
          </div>
        </div>
        <CustomModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          className="getStarted"
        >
          <GetStartedForm hasText />
        </CustomModal>
      </section>
    </>
  );
};

export default HomeIndex;
