import * as React from "react";
import styled from "styled-components";
import { CustomLinkBtn } from "../../../Components/CustomButton";
import { BsArrowUpRight } from "react-icons/bs";
import { Link } from "gatsby";
import { BiChevronRight } from "react-icons/bi";
import FrontDeskLogo from "../../../../public/assets/icons/FrontDeskLogo";

const ProductCard = ({ src, product, description, url, isProduct }) => {
  const CustomCard = styled.div`
    background: #ffffff;
    border: 1px solid #0000001C;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    align-items: start;
    // cursor: pointer;
    min-height: 180px;
    font-family: Poppins, sans-serif;
    

    .text-section {
      height: 140px;
      position: relative;
      h5 {
        font-size: 15px ;
        font-weight: bold;
        color: #000;
      }

      p {
        font-size: 12px;
        font-weight: 300;
        color: #929292;
        max-width: 365px;
      }

      a {
        font: normal normal normal 13px/20px Poppins;
        letter-spacing: 0px;
        // color:  !important;
        cursor: pointer;
        transition: all 0.4 ease;
        font-size: 0.85rem;
        font-weight: 400;
        position: absolute;
        bottom: 0px;
       
        // padding: 8px 25px;
        border-radius: 40px 40px 39px 40px;
  
        svg {
          font-size: 20px;
          transition: transform 0.4s ease;
        }
  
        &:hover {
          // background: var(--btn-gradient);
          // color: var(--white-color);
        }
  
        &:hover svg {
          transform: translateX(5px);
        }
      }
    }

    .img-section {
      margin-right: 20px;
      position: relative;
      // border-radius: 50px;
      //   box-shadow: 0px 0px 6px #00000029;
      //   padding: 5px;
      //   width: 70px;
      //     height: 70px;
      img {
        width: 70px;
        height: 70px;
      }
    }

    h6 {
      font: normal normal normal 13px/20px Poppins;
      color: #2333c4;
    }


    @media only screen and (min-device-width: 280px) and (max-device-width: 767px) {
      margin-inline: 20px;
      .img-section {
        
        img {
          width: 60px;
          height: 60px;
          
        }
      }

      .text-section {
        h5 {
          font: normal normal normal 14px/18px Poppins;
          color: #000;
        }

        p {
          font: normal normal normal 12px/15px Poppins;
          color: #929292;
          max-width: 365px;
        }

        h6,
        a {
          font: normal normal normal 10px/20px Poppins;
        }
      }
    }
  `;

  return (
    <Link to={url}>
      <CustomCard>
        <div className="img-section">
          <img src={src} alt=""/>
          
        </div>
        <div className="text-section">
          <h5>{product}</h5>
          <p>{description}</p>
          {/* {soon ? (
            <a>Coming Soon!</a>
          ) : (
            // <CustomLinkBtn text="Learn More" url={url} />
            <Link to="">
           </Link>
          )} */}

            <a style={{color: isProduct ? '#000' : '#2333c4'}}>
           Explore <BiChevronRight />
            </a>
        </div>
      </CustomCard>
   </Link>
  );
};

export default ProductCard;
