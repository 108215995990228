import * as React from 'react'
import styled from 'styled-components'
import { PatternIcon } from '../../../Components/SVGs'

const CardWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding-block: 60px;
  display: flex;
  z-index: 10;

  .html5-video-player .video-click-tracking,
  .html5-video-player .video-stream {
    display: block;
    width: 100% !important;
    height: 100%;
    position: absolute;
    left: 0 !important;
  }

  .pattern {
    position: absolute;
    z-index: 1;

    svg {
      z-index: 1;
    }
  }

  .pattern1 {
    top: 0;
    right: -20px;
    z-index: 1;
  }

  .pattern2 {
    bottom: 0;
    left: -55px;
    z-index: 1;
  }
`

const CustomCard = styled.div`
  width: 100%;
  ${'' /* height: 510px; */}
  z-index: 2;

  .container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  }

  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

`

function VideoCard() {
  return (
    <CardWrapper>
      <div className="pattern pattern1">
        <PatternIcon />
      </div>

      <CustomCard>
        <div class="container">
          <iframe
            class="responsive-iframe"
            src="https://www.youtube.com/embed/PhO7prCJR9Y"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </CustomCard>
      <div className="pattern pattern2">
        <PatternIcon />
      </div>
    </CardWrapper>
  )
}

export default VideoCard
